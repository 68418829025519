import imgCanelle from '../../../assets/logoCanelle.webp'
import imgDuasxdoce from '../../../assets/logoDuasxdoce.webp'
import imgTratoria from '../../../assets/logoTratoria.svg'
import imgHDO from '../../../assets/logoHDO.webp'
import imgLascaDePizza from '../../../assets/logoLascaDePizza.webp'
import imgRecantoDoPicui from '../../../assets/logoRecantoDoPicui.webp'

import * as Styled from './styles'

export default function empresasMotoboy() {
  return (
    <Styled.Content>
      <div className="container-fluid">
        <Styled.Empresas>
          <img src={imgTratoria} alt="logo trattoria" />
          <img src={imgCanelle} alt="logo canelle" />
          <img src={imgDuasxdoce} alt="logo duas x doces confeitaria" />
          <img src={imgHDO} alt="logo okane" />
          <img src={imgLascaDePizza} alt="logo lasca de pizza" />
          <img src={imgRecantoDoPicui} alt="logo recanto do picui" />
        </Styled.Empresas>
      </div>
    </Styled.Content>
  )
}
