import mapaMoovery from '../../../assets/mapaMoovery2.webp'
import imgIfood from '../../../assets/ifood.webp'
import imgSaipos from '../../../assets/Saipos_logo.png'
import imgAnotaAi from '../../../assets/Anota-AI.png'
import imgNemo from '../../../assets/nemo.webp'
import imgDeliveryDireto from '../../../assets/deliveryDireto.webp'
import imgMeuCardapio from '../../../assets/meuCardapio.webp'
import imgMenew from '../../../assets/menew.webp'
import Zoom from 'react-reveal/Zoom'
import * as Styled from './style'
import VantagensEmpresa from '../../molecules/VantagensEmpresa'
import EntreEmContato from '../EntreEmContato'
import ProcessoDeEntrega from '../ProcessoDeEntrega'

export default function VantagensParaEmpresa() {
  return (
    <>
      <Styled.Container>
        <Zoom>
          <Styled.ElementVantagens>
            <div className="descricaoGroup">
              <Styled.TitleGroupLeft>
                <Styled.Title>Junte-se a nós</Styled.Title>
                <Styled.SubTitle>Conheça nossas vantagens</Styled.SubTitle>
              </Styled.TitleGroupLeft>
              <p style={{ textAlign: 'left' }}>
                Entregamos nosso serviço de pessoas para pessoas <br /> com ética, responsabilidade,
                transparência, empatia, liderança e inovação.
              </p>
            </div>
            <VantagensEmpresa />
          </Styled.ElementVantagens>
        </Zoom>
        <Styled.BackgroundPurple>
          <Zoom>
            <Styled.ElementVantagensWhite>
              <div className="imgsGroup">
                <div className="imgLine">
                  <img src={imgDeliveryDireto} alt="" />
                  <img src={imgMeuCardapio} alt="" />
                  <img src={imgNemo} alt="" />
                </div>
                <div className="imgLine">
                  <img src={imgMenew} alt="" />
                  <img src={imgIfood} alt="" />
                  <img style={{backgroundColor: 'white', padding: '.3rem'}} src={imgSaipos} alt="" width={125} height={125}/>
                  <img style={{backgroundColor: 'white'}}  src={imgAnotaAi} alt="" />
                </div>
              </div>
              <div className="descricaoGroup">
                <div className="titleGroup">
                  <Styled.Title>Integrações</Styled.Title>
                  <Styled.SubTitle>Conheça nossas integrações!</Styled.SubTitle>
                </div>
                <p style={{ textAlign: 'right' }}>
                  Integramos com as principais plataformas de logística do <br /> Brasil! Ifood,
                  Menew, Delivery Direto, Nemo e Meu cardápio.
                </p>
              </div>
            </Styled.ElementVantagensWhite>
          </Zoom>
        </Styled.BackgroundPurple>
        <ProcessoDeEntrega />

        <Styled.BackgroundPurple>
          <Zoom>
            <Styled.ElementVantagens>
              <div className="descricaoGroup">
                <Styled.TitleGroupLeft>
                  <Styled.Title>Localidades atendidas</Styled.Title>
                  <Styled.SubTitle>João Pessoa-PB / Natal-RN / Campina Grande - PB</Styled.SubTitle>
                </Styled.TitleGroupLeft>
                <p style={{ textAlign: 'left' }}>
                  Estamos trabalhando para expandir cada vez mais as localidades atendidas. <br />
                  Entre em contato e saiba as próximas regiões.
                </p>
              </div>
              <Styled.ImgMapa src={mapaMoovery} alt="" />
            </Styled.ElementVantagens>
          </Zoom>
        </Styled.BackgroundPurple>
        <Zoom>
          <EntreEmContato />
        </Zoom>
      </Styled.Container>
    </>
  )
}
